.TypoLectureMain-con {
    display: flex;
    flex-direction: column;
    
    background-color: #fff;
}

.TypoLectureMain-head {
    background-color: #65eaff; /* 상단 head 색상 */
    height: 8%;
    padding: 0.5%;
    display: flex;
    
    justify-content:center;
    align-items: center;
}

.TypoLectureMain-bodycon {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.TypoLectureMain-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; /* Fill the remaining height */
    background-color: #fff;
}

.TypoLectureMain-body-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    padding: 20px;
    
}
