.Cws-left-sec-con {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

.Cws-left-sec-head {
    position: relative;
    margin-top: 3%;
    height: 5%;
    display: flex;
    background-color: #fbfbfb;
    flex-direction: row;
}

.Cws-left-sec-tab {
    width: 25%;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 10px 10px 0px 0px;
    background-color: #f7f7f7;
    color: #646464;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 700;
}

.Cws-left-sec-tab.Cws-left-sec-active {
    background-color: #fff;
    color: #5BD2D9 ;
    border-top: 2px solid #e0e0e0;
    border-left: 2px solid #e0e0e0;
    border-right: 2px solid #e0e0e0;
}

.Cws-left-sec-body {
    display: flex;
    flex-direction: column;
    height: 92%;
    border: 2px solid #e0e0e0;
    background-color:#F7F7F7;
    border-radius: 0px 10px 10px 0px;
}

.Cws-left-sec-tab-content {
    flex: 1;
    overflow: auto;
    
}

.Cws-left-sec-board-content {
    background-color: #fff;
    border-radius: 0px 10px 10px 0px;
}

.Cws-left-sec-project-content {
    background-color: #f9d0d0;
    border-radius: 0px 10px 10px 0px;
}

