/* TypoLectureSection.css */
.TypoLectureSection-con {
    padding: 20px;
    display: flex;
    justify-content: center;
    background-color: #1e1e1e;
    background-color: #fff;
    height: 100vh;
}

.TypoLectureMain-body {
    width: 100%;
    max-width: 1200px;
    margin-bottom: 2rem;
}

.TypoLectureGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

.TypoLectureGrid-box {
    position: relative;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;                            
    color: white;
    height: 200px;
    display: flex;
    align-items: flex-end;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    text-decoration: none; /* 밑줄 제거 */
    
}

.TypoLectureGrid-box:hover {
    transform: scale(1.05);
}

.TypoLectureGrid-box-title {
    font-size: 1.2rem;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .TypoLectureGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .TypoLectureGrid {
        grid-template-columns: repeat(1, 1fr);
    }
}
