@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100..900&family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

body {
  height:100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',"Noto Sans Mono", 'Inter' ,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
/* English font */
.font-en {
  font-family: 'Roboto', sans-serif,'Inter';
}

/* Korean font */
.font-ko {
  font-family: 'Noto Sans KR', sans-serif,'EliceDigitalBaeum-Bd';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'EliceDigitalBaeum-Bd';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_elice@1.0/EliceDigitalBaeum-Bd.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    
}
@import url('https://fonts.googleapis.com/css2?family=B612+Mono:ital,wght@0,400;0,700;1,400;1,700&family=Inter:wght@600&display=swap');