.Cws-toggle-con{
    background-color: white;
    width: 98%;
    border-radius: 10px;
    border: 2px solid #E0E0E0;
    padding-right: 1%;
    padding-left: 1%;
    color: #828282;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 400;
}
.Cws-toggle-icon{
    cursor: pointer;
}
.Cws-toggle-editor-con {
    
    width: 98%;
    height: 360px; /* Adjust height as needed */
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1%;
    padding-left: 1%;
}

/* Apply styles to the Monaco Editor container */
.monaco-editor {
    border-radius: 10px;
    overflow: hidden; /* Ensure the content doesn't overflow the rounded corners */
}
/* CwsToggleEditor.css */
.error-line {
    background-color: rgba(255, 0, 0, 0.2); /* Red background with some transparency */
}
