/* 전체 컨테이너 설정 */
.DemoShowPage-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #2c2c2c;
  color: #fff;
}

/* 상단 헤더 설정 */
.DemoShowPage-header {
  background-color: #2a9df4;
  height: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.DemoShowPage-header h2 {
  margin: 0;
}

/* 본문 전체 컨테이너 설정 */
.DemoShowPage-body {
  display: flex;
  height: 90%;
  padding-top: 1rem;
}

/* 왼쪽 영역 스타일 */
.DemoShowPage-left {
  background-color: #2c2c2c;
  width: 30%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-width: 200px;
}

/* 가운데 에디터와 실행 버튼 영역 */
.DemoShowPage-center {
  background-color: #2c2c2c;
  width: 32%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.DemoShowPage-editor-run {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DemoShowPage-run-button {
    background-color: #30c38d;
  border: none;
  color: white;
  padding: 10px 18px;
  font-size: 16px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}

.DemoShowPage-run-button:hover {
  background-color: #30c38d;
}

.DemoShowPage-editor {
  height: 90%;
  background-color: #1e1e1e;
  padding: 10px;
  border-radius: 10px;
}

/* 오른쪽 결과 표시 영역 */
.DemoShowPage-right {
  background-color: #2c2c2c;
  width: 40%;
  display: flex;
  flex-direction: column;
  position: relative; /* 버튼 고정을 위해 relative 추가 */
}

.DemoShowPage-result {
  flex: 3;
  background-color: #333;
  margin: 10px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  max-height: calc(75% - 20px);
  width: 100%;
}

/* 버튼 영역 */
.DemoShowPage-buttons {
  flex: 1;
  display: flex;
  justify-content: flex-start; /* 왼쪽부터 채우기 */
  align-items: center;
  background-color: #444;
  height: 100px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: relative;
}

/* 추가된 섹션 버튼들 */
.DemoShowPage-button {
  width: 80px;
  height: 80px;
  background-color: #2a9df4;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.DemoShowPage-button:hover {
  background-color: #1e87d1;
}

/* 섹션 추가 버튼을 오른쪽 하단에 고정 */
.DemoShowPage-add-section-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  padding: 10px 20px;
  background-color: #2a9df4;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.DemoShowPage-add-section-button:hover {
  background-color: #1e87d1;
}

/* 모달 창 스타일 */
.DemoShowPage-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1e1e1e;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
}

.DemoShowPage-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.DemoShowPage-modal-options {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.DemoShowPage-modal-option {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2a9df4;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}

.DemoShowPage-modal-icon {
  margin-right: 10px;
}

.DemoShowPage-modal-option:hover {
  background-color: #1e87d1;
}

.DemoShowPage-modal-close-button {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}
.DemoShowPage-centered-image-con{
  margin-top: 8rem;
  display: flex;
  justify-content: center;
  align-content: center;
}
.DemoShowPage-centered-image {
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 20px;
}

.DemoShowPage-circle-image {
  width: 200px; /* Adjust size as needed */
  height: 200px; /* Adjust size as needed */
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image covers the area */
  border: 2px solid #333; /* Optional: Add a border around the image */
  transition: opacity 0.3s; /* Smooth transition for the hover effect */
}