.typo-explore-tot-con{
    width: 100%;
    background-color: #fff;
    overflow-x: hidden;
}
.typo-explore-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    font-family: 'Pretendard-Regular', sans-serif;
    font-weight: 700;
    background-color: #fff;
}

.typo-explore-head {
    width: 100%;
    text-align: center;
    margin: 20px 0;
}
.typo-explore-nav-head {
    background-color: #65eaff;
    height: 8%;
    width: 100%;
    padding: 0.5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.typo-explore-head h2 {
    color: #333;
    font-size: 24px;
    font-weight: bold;
}

.typo-explore-head span {
    color: #777;
    font-size: 16px;
}



.typo-explore-body {
    width: 100%;
}

.typo-explore-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 20px;
}
.typo-explore-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.typo-explore-card {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid #65eaff;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
}

.typo-explore-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.typo-explore-card-image img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-bottom: 2px solid #65eaff;
}

.typo-explore-card-content {
    padding: 15px;
    text-align: center;
}

.typo-explore-card-content h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

.typo-explore-card-content p {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.typo-explore-footer{
    height: 10vh;
}

.typo-explore-card-stats {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    color: #777;
}

.typo-custom-button {
    background-color: #65eaff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-right: 10px;
    margin-left: 10px;
}

.typo-custom-button:hover {
    background-color: #339edb;
    transform: scale(1.05);
}

.typo-custom-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
.typo-explore-button-box{
    margin-top: 30px;
    
}