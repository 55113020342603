.Cws-nav-con{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
}
.Cws-nav-box-l{
    flex:1;
    display: flex;
    flex-direction: row;
    padding-left: 1%;
    align-items: center;

}
.Cws-nav-box-m{
    flex:1;
    
    
}
.Cws-nav-box-r{
    flex:1;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 700;
}
.Cws-nav-item-box{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    
    margin-right: 5%;
    color: #fff;
}
.language-selector {
    margin-left: 20px;
    padding: 5px;
    border: 1px solid #60d8e0;
    border-radius: 5px;
    background-color:  #fff;
    color: #60d8e0;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 700;
}