.upload-image-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    font-family: 'Pretendard-Regular';
}

.upload-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.upload-image-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.upload-image-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 2px dashed #5BD2D9;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    width: 80%;
    text-align: center;
}

.upload-image-label input {
    display: none;
}

.upload-image-label span {
    color: #5BD2D9;
    font-size: 14px;
}

.upload-image-button {
    background-color:#5BD2D9;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.upload-image-button:hover {
    background-color: #5BD2D9;
}

.Cws-upload-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #5BD2D9;
}

.Cws-upload-close-icon:hover {
    opacity: 0.8;
}
