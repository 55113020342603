/* Container for the login page */
.Typo-login-con {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff; /* Light background color for the entire page */
    font-family: 'Pretendard-Regular', 'Inter', sans-serif;
    font-weight: 700;
    overflow-y: hidden;
}

/* Navigation and header section */
.typo-explore-nav-head {
    width: 100%;
    
    margin-bottom: 20px; /* Space between nav and body */
}

.Typo-login-head {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Typo-login-head h1 {
    color: #333; 
    font-size: 36px;
    margin: 0;
}

/* Login and registration form body */
.Typo-login-body {
    width: 80%;
    max-width: 600px; /* Maximum width for body */
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #65eaff;
    gap: 20px;
    padding: 30px;
    border-radius: 15px; /* More rounded corners */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
}

/* Form fields container */
.Typo-login-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
}

.Typo-login-box span {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
}

.Typo-login-box input, .Typo-login-box select {
    border: 2px solid #65eaff;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, background-color 0.3s ease;
}

.Typo-login-box input:focus, .Typo-login-box select:focus {
    border-color: #007acc;
    background-color: #e6f7ff;
    outline: none;
}

/* Custom button style */
.Typo-custom-login-button {
    font-family: 'Pretendard-Regular', 'Inter', sans-serif;
    font-weight: 700;
    background-color: #fff;
    color: #65eaff;
    border: 2px solid #65eaff;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
    border-radius: 15px;
    padding: 10px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.Typo-custom-login-button:hover {
    background-color: #65eaff;
    border-color: #65eaff;
    color: #fff;
}

.Typo-login-footer {
    margin-top: 2%;
    width: 100%;
    display: flex;
    justify-content: center;
}

/* Footer styling */
.Typo-login-footer-foot {
    width: 100%;
    background-color: #fff;
    color: #fff;
    
    text-align: center;
    border-radius: 15px; /* Rounded corners to match the design */
    
    margin-top: 40px; /* Space between body and footer */
}

/* Dropdown styling */
.Typo-login-box select {
    background-color: #fff;
    color: #333; 
    font-weight: 700;
    cursor: pointer;
}

.Typo-login-box select option {
    color: #333; 
}

/* Additional customization for student/teacher selection */
.Typo-login-box select {
    appearance: none; /* Remove default arrow */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%23007acc" d="M2 0L0 2h4z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;
}
