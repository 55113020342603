#root, .App {
  height: 100%;
  background: #111; /* Ensure the background color is consistent */
}
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #111; /* Set the background color you want */
}

