.Cws-main-con {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

.Cws-main-head {
    background-color:#65eaff;/* 상단 head 색상 */
    height: 4%;
    padding: 0.5%;
}

.Cws-main-body {
    display: flex;
    height: 94%; /* 나머지 body가 차지하는 공간 */
}

.Cws-main-left {
    background-color: #FBFBFB; /* left 구역 색상 */
    width: 36%;
    display: flex;
    min-width: 200px; 
    flex-direction: column;
}

.Cws-main-center {
    background-color: #FBFBFB; /* center 구역 색상 */
    width: 32%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
}

.Cws-main-right {
    background-color: #FBFBFB; /* right 구역 색상 */
    width: 32%;
    display: flex;
    flex-direction: column;
}

.swal2-confirm{
    background-color:#29FF48 !important;
}
