.typo-work-ex-wrap {
    width: 100%;
    background-color: #fff;
}

.typo-work-ex-nav-head {
    background-color: #65eaff;
    height: 8%;
    padding: 0.5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.typo-work-ex-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 400;
}

.typo-work-ex-head {
    width: 70%;
    text-align: center;
    margin: 20px 0;
    color: #65eaff;
    font-size: 24px;
    font-weight: bold;
}

.typo-work-ex-body {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.typo-work-ex-result-con {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #f0f8ff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 400;
}

.typo-work-ex-result-iframe {
    width: 45%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
}

.typo-work-ex-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.typo-work-ex-result-script {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    color: #333;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 400;
}

.typo-work-ex-buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.typo-work-ex-run-button,
.typo-work-ex-revise-button,
.typo-work-ex-like-button {
    background-color: #65eaff;
    border: none;
    padding: 10px 20px;
    color: #fff;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 400;
}

.typo-work-ex-run-button:hover,
.typo-work-ex-revise-button:hover,
.typo-work-ex-like-button:hover {
    background-color: #339edb;
}

.typo-work-ex-comment-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #65eaff;
    padding: 20px;
    border-radius: 10px;
}

.typo-work-ex-comment-box-list {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
}

.typo-work-ex-comment {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.typo-work-ex-comment-input {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.typo-work-ex-comment-input input {
    width: 70%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.typo-work-ex-comment-input button {
    padding: 10px 20px;
    background-color: #339edb;
    border: none;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.typo-work-ex-comment-input button:hover {
    background-color: #1b6aa8;
}

.typo-work-ex-comment-login-message {
    text-align: center;
    color: #fff;
}
