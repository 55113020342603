.Cws-res-iframe{
    border-radius: 10px;
    border: 2px solid #E0E0E0;
}

/* Modal overlay */
.Cws-res-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is on top */
}

/* Modal body */
.Cws-res-modalBody {
    position: relative;
    width: 500px;
    padding: 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Modal content */
.Cws-res-modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Close button */
#Cws-res-modalCloseBtn {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background-color: transparent;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
}

#Cws-res-modalCloseBtn:hover {
    color: rgba(0, 0, 0, 1);
}
