.Cws-load-modal {
    display: flex;
    width: 70%;
    height: 80%;
    margin: 5% auto;
    background-color: #f0f0f0;
    border-radius: 10px;
    position: relative; /* Ensure relative positioning for child elements */
    overflow: hidden; /* Hide overflow to enable scrolling within content */
}

.Cws-load-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.Cws-load-sidebar {
    width: 20%;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure button stays at the bottom */
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 10px;
}

.Cws-load-class-container {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Add space between class boxes */
}

.Cws-load-class-box {
    background: #Def6f7;
    border: none;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    color:  #86EBF1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Cws-load-class-box.active {
    background-color:  #86EBF1;
    color: #fff;
}

.Cws-load-content {
    width: 80%;
    padding: 2%;
    overflow-y: auto; /* Enable vertical scrolling */
}

.Cws-load-chapter-toggle {
    background: #EFEFEF;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid #ddd; /* Darken the border */
}

.Cws-load-projects {
    padding-left: 20px;
    max-height: 200px; /* Set a max height for projects to enable scrolling */
    overflow-y: auto; /* Enable vertical scrolling for projects */
}

.Cws-load-project-box {
    background: #fff;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 5px;
    border: 1px solid #ddd; /* Darken the border */
}

.Cws-load-project-box.active {
    background-color:  #86EBF1;
    color: #fff;
}

.Cws-load-load-button {
    margin-top: auto;
    padding: 10px;
    background-color: #86EBF1;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.Cws-load-load-button:hover {
    background-color: #86EBF1;
}

.Cws-load-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #86EBF1;
}
