.Cws-code-editor-con{
    display: flex;
    flex-direction: column;
    height: 100vh;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    width:96%;
    padding-left: 2%;
    padding-right: 2%;

    
}
.Cws-code-editor-head{
    position: relative;
    margin-top: 3%;
    height: 5%;
    display: flex;
    background-color: #fbfbfb;
    flex-direction: row;
    border-radius: 10px;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 700;
}
.Cws-code-editor-head-half-l{
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Cws-code-editor-head-half-r{
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 1%;
}
.Cws-code-editor-body{
    height: 93%;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    padding-bottom: 2%;
    overflow: hidden;
    
}
.Cws-code-editor-scroll-wrap{
    height: 100%;
    display: flex;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    
    border: 2px solid #e0e0e0;
}
.Cws-code-editor-backstage{
    position: absolute;
    background-color:#F7F7F7;
    height: 98%;
    width: 96%;
    border-end-start-radius: 10px;
    padding: 2%;
    gap: 3%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;   
    
}
.Cws-code-explain-toggle {
    color: #606060;
    font-size: 12px;
    margin-bottom: 4px; /* Space between explanation text and toggle editor */
    font-family: "Inter";
    font-weight: 500;
}
/* Hide scrollbar by default */
.Cws-code-editor-backstage::-webkit-scrollbar {
    width: 10px;
    background: transparent;
    
}


/* Style the scrollbar thumb */
.Cws-code-editor-backstage::-webkit-scrollbar-thumb {
    border: 5px solid #E0E0E0;
    background: transparent;
    border-radius: 10px;
}

.Cws-code-editor-custom-button {
    background-color: #e5fdff;
    color:  #60d8e0;
    border: 2px solid #60d8e0;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    font-family: 'Inter';
    border-radius: 5px; /* Rounded corners for a pill-like shape */
    padding: 5px 10px; /* Increased horizontal padding */
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 10%; /* Ensure it doesn't shrink too much */
    text-align: center;
}

.Cws-code-editor-custom-button:hover {
    background-color: #60d8e0;
    border: 2px solid #60d8e0;
    color: #fff;
    
}
.Cws-command-copy-icon {
    margin-left: 50%;
    cursor: pointer;
}
.Cws-code-editor-tab{
    width: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 10px 10px 0px 0px;
    background-color: #f7f7f7;
    color: #646464;
    height: 100%;
}
.Cws-code-editor-tab.Cws-code-editor-active {
    background-color: #fff;
    color: #5BD2D9 ;
    border-top: 2px solid #e0e0e0;
    border-left: 2px solid #e0e0e0;
    border-right: 2px solid #e0e0e0;
}
.Cws-fixed-editor-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Cws-fixed-editor{
    width: 95%;
    
}

.Cws-fixed-editor-name-con{
    display: flex;
    flex-direction: row;
    align-items: center;    
    justify-content: center;
    padding: 1% ;
}

.Cws-scene-list-container-wrap{
    display: flex;
    width: 100%;
flex-direction: column;
justify-content: center;
align-items: center;
height: 30%; 
}

.scene-list-container {
    margin-top: 2%;
    display: flex;

    flex-direction: column;

    width: 95%;
    
    overflow: auto;
    background-color:white;
    border-radius: 10px;
    border: 2px solid #E0E0E0;
}

.add-scene-container {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fbfbfb;
}

.add-scene-container input {
    flex: 1;
    padding: 5px;
    margin-right: 5px;
}

.add-scene-container button {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #60d8e0;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;    
}

.add-scene-container button:hover {
    opacity: 0.8; /* Makes the box slightly transparent on hover */
    transform: scale(1.05); /* Slightly scales up the box on hover */
}

.scene-list {
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow-y: auto;
}

.scene-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-self: center;
    align-items: center;
    border-radius: 10px;
}

.scene-item.active {
    background-color:#60d8e0;
    color: #f7f7f7;
}

/* Hide scrollbar by default */
.scene-list::-webkit-scrollbar {
    width: 10px;
    background: transparent;
    
}

/* Show scrollbar on hover */
.scene-list:hover::-webkit-scrollbar {
    width: 10px;

}

/* Style the scrollbar thumb */
.scene-list::-webkit-scrollbar-thumb {
    border: 5px solid #E0E0E0;
    background: transparent;
    border-radius: 10px;
}
