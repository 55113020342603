.Cws-command-con {
    margin: 7px 0;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
    padding: 5px;
    background-color: #f4f4f4;
    width: 95%;
    
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
}

.Cws-command-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1%;
    background-color: #f4f4f4;
    border-radius: 5px;
    position: relative;
}

.Cws-command-round-box {
    border-radius: 5px;
    background-color: #fff;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 5%;
    max-width: 10%;
    text-align: center;
    font-family: "Noto Sans Mono";
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.Cws-command-con:hover {
    background-color: #def6f7;
}



.Cws-command-name {
    font-weight: bold;
}

.Cws-command-params {
    margin-left: 10px;
    color: #888;
}

.Cws-command-body {
    
    background-color: #fff;
    margin-top: 10px;
    padding: 2%;
    border-radius: 10px;
}


.Cws-command-body pre{
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
}
.Cws-command-body strong{
    color:  #60d8e0;
}

.Cws-command-description {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 2%;
    border-radius: 10px;
}

.Cws-command-example {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 2%;
    border-radius: 10px;
}

.Cws-command-example pre {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    white-space: pre-wrap;
    word-wrap: break-word;
    
}

.Cws-command-header:hover .Cws-command-tooltip {
    display: block;
}

.Cws-command-tooltip {
    display: none;
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    top: 100%;
    left: 40%;
    transform: translateX(-50%) translateY(10px);
    z-index: 1;
    white-space: nowrap;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    line-height: 1.5;
}

.Cws-command-tooltip::after {
    content: '';
    position: absolute;
    top: -10px;
    left: 20%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
}

.Cws-command-copy-icon {
    position: absolute;
    right: 1%;
    top: 30%;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.Cws-command-copy-icon.copied {
    transform: scale(1.2);
}

.Cws-command-example img {
    width: 75%;
    display: block;
    margin: 10px auto;
    border-radius: 10px;
}
