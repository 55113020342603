.Cws-bg-con {
    display: flex;
    flex-direction: column;
    gap: 1%; /* Space between detail and list sections */
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #E0E0E0;
}

.Cws-bg-detail {
    background-color: #c2f8fd;
    padding: 2%;
    border-radius: 10px;
    color: #1a1d31;
    display: flex;
    flex-direction: column;
    gap: 5px; /* Space between sections */
    height: 30%;
    overflow: hidden;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 700;
}

.Cws-bg-main {
    height: 70%;
    width: 100%;
    overflow: hidden;
}

.Cws-bg-main-scroll-wrap {
    display: flex;
    flex-direction: column;
}

.Cws-bg-list {
    display: flex;
    flex-wrap: wrap;
    height: 140px;
    gap: 10px; /* Space between background boxes */
    overflow-y: auto;
    overflow-x: hidden;
    position: relative; /* To position the add icon relative to this container */
    padding: 1%;
}

/* Hide scrollbar by default */
.Cws-bg-list::-webkit-scrollbar {
    width: 10px;
    background: transparent;
}

/* Show scrollbar on hover */
.Cws-bg-list:hover::-webkit-scrollbar {
    width: 10px;
}

/* Style the scrollbar thumb */
.Cws-bg-list::-webkit-scrollbar-thumb {
    border: 5px solid #E0E0E0;
    background: transparent;
    border-radius: 10px;
}

.Cws-bg-init-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.Cws-bg-init-info-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #5BD2D9;
    margin-bottom: 2%;
}

.Cws-bg-box {
    position: relative;
    width: calc(20% - 10px); /* Make sure 5 boxes fit per row, considering the gap */
    height: 60%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #E0E0E0;
    border-radius: 10px;
    cursor: pointer;
    color: #1a1d31;
    transition: opacity 0.3s, transform 0.3s, background-color 0.3s;
    
}

.Cws-bg-box-name {
    width: 100%;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    position:absolute;
    top: 80%;
}

.Cws-bg-img-box {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Cws-bg-img-box img {
    max-width: 80%;
    max-height: 80%;
    height: auto;
    width: auto;
    object-fit: scale-down;
}

.Cws-bg-box.add-new {
    background-color: #111;
    color: #5BD2D9;
    border: 2px solid #5BD2D9;
    border-radius: 10px;
}

.Cws-bg-box:hover, .Cws-bg-box.add-new:hover {
    opacity: 0.8; /* Makes the box slightly transparent on hover */
    transform: scale(1.05); /* Slightly scales up the box on hover */
    border-color: #5BD2D9;
}

.Cws-bg-box.active {
    border-color: #5BD2D9;
    background-color: #fff;
}

.Cws-bg-box.active .Cws-bg-box-name {
    border: 2px solid #5BD2D9;
    border-radius: 6px;
    color: #fff;
    background-color: #5BD2D9;
}

.Cws-bg-box.inactive {
    opacity: 0.5;
}

.Cws-bg-delete-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    color: #ff0000;
}

.Cws-bg-delete-icon:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

.Cws-bg-rename-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    cursor: pointer;
    color: #888;
    transition: color 0.3s;
}

.Cws-bg-rename-icon:hover {
    color: #333;
}

.Cws-bg-add-icon {
    position: fixed;
    bottom: 20px;
    right: 30px;
    cursor: pointer;
    color: #5BD2D9;
}

.Cws-bg-add-icon:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

.Cws-bg-upload-icon {
    position: fixed;
    bottom: 20px;
    right: 70px;
    cursor: pointer;
    color: #007bbd;
}

.Cws-bg-upload-icon:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

.Cws-bg-selected {
    border-color: #5BD2D9;
    background-color: #c2f8fd;
}

.Cws-bg-custom-button {
    border: 2px solid #5BD2D9;
    background-color: #fff;
    color: #5BD2D9;
    padding: 10px 20px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.Cws-bg-custom-button:hover {
    background-color: #5BD2D9;
    border-color: #5BD2D9;
    color: #fff; /* White text color on hover */
}

.Cws-bg-detail-round-box {
    border-radius: 20px; /* Rounded corners for a pill-like shape */
    background-color: #fff;
    padding: 5px 20px; /* Increased horizontal padding */
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20%; /* Ensure it doesn't shrink too much */
    max-width: 20%; /* Ensure it doesn't shrink too much */
    text-align: center;
    font-family: "Noto Sans Mono";
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
}

.Cws-bg-detail-controls {
    display: flex;
    flex-direction: row;
    gap: 25px; /* Space between control items */
    align-items: center;
}

.Cws-bg-control-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 40%;
    gap: 5px; /* Space between icon, label, and box */
}

.Cws-bg-detail-icon {
    color: #1a1d31; /* Icon color */
}

.Cws-bg-control-item span {
    font-size: 14px; /* Label font size */
}

/* Style for master backgrounds */
.Cws-bg-box.master {
    position: relative;
    border: 2px solid transparent;
    background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, #a8e6ff, #5BD2D9, #007bbd, #5BD2D9, #a8e6ff);
    background-origin: border-box;
    background-clip: content-box, border-box;
    animation: gradient-rotate-clockwise 10s linear infinite;
    background-size: 200% 200%;
}

.Cws-bg-box.master.active {
    border: 2px solid #5BD2D9; /* Keeps the current selection style */
}

@keyframes gradient-rotate-clockwise {
    0% {
        background-position: 0% 0%;
    }
    25% {
        background-position: 100% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    75% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}
