.Cws-cmd {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    border-radius: 10px;
}

.Cws-cmd-detail span{
    position: absolute;
    right: 40%;
    top: 25%;
}

.Cws-cmd-detail {
    position: relative;
    background-color: #C2F8FD; /* Light green background */
    border-bottom: 2px solid #60D8E0;
    padding: 2%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #383838;
    display: flex;
    flex-direction: column;
    gap: 5px; /* Space between sections */
    height: 10%;
    overflow: hidden;
    font-family: "Inter";
}

.Cws-cmd-main {
    height: 90%;
    width: 100%;
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #484848;
}

.Cws-cmd-main-scroll-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Cws-cmd-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;   
    padding: 1%;
    background-color: #484848;
    justify-content: flex-start;
}

.Cws-cmd-line {
    width: 100%;
    height: auto;
    border-radius: 10px;
    padding-left: 10px;
    margin-bottom: 5px;
    color: #fff;
    font-family: "B612 Mono";
}

/* Hide scrollbar by default */
.Cws-cmd-list::-webkit-scrollbar {
    width: 10px;
    background: transparent;
}

/* Style the scrollbar thumb */
.Cws-cmd-list::-webkit-scrollbar-thumb {
    border: 2px solid #60D8E0;
    background: transparent;
    border-radius: 10px;
}
