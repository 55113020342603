.Cws-ob-selection-modal {
    display: flex;
    width: 60%;
    height: 80%;
    margin: 5% auto;
    background-color: #f0f0f0;
    border-radius: 10px;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 700;
}

.Cws-ob-selection-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.Cws-ob-selection-sidebar {
    width: 20%;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 10px;
}

.Cws-ob-selection-sidebar::-webkit-scrollbar {
    display: none;
}

.Cws-ob-selection-sidebar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.Cws-ob-selection-category-top {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Cws-ob-selection-category-box {
    margin-bottom: 10px;
    background: #DeF6f7;
    border: none;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    color: #86EBF1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.Cws-ob-selection-inputs input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    width: 80%;
    border-radius: 10px;
}

.Cws-ob-selection-inputs input:focus {
    border-color: #86EBF1;
    outline: none;
}

.Cws-ob-selection-selection-con {
    width: 80%;
    padding: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Cws-ob-selection-page {
    width: 90%;
    height: 90%;
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    padding: 20px;
    overflow-y: auto;
}

.Cws-ob-selection-box {
    position: relative;
    width: calc(15% - 10px);
    height: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #E0E0E0;
    background-color: #fff;
    justify-content: center;
}

.Cws-ob-selection-box:hover {
    opacity: 0.8;
    transform: scale(1.05);
    border-color: #86EBF1;
}

.Cws-ob-selection-box.active {
    border-color: #86EBF1;
    background-color: #fff;
}

.Cws-ob-selection-img-box {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Cws-ob-selection-img-box img {
    max-width: 80%;
    max-height: 80%;
    height: auto;
    width: auto;
    object-fit: contain;
}

.Cws-ob-selection-box-name {
    text-align: center;
    color: #1a1d31;
    width: 100%;
    height: 30%;
    font-family: "Inter";
    margin-top: 10%;
    font-size: 12px;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Cws-ob-selection-custom-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #86EBF1;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 10px;
}

.Cws-ob-selection-custom-button:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

.Cws-ob-selection-close-icon {
    position: fixed;
    top: 12%;
    left: 78%;
    cursor: pointer;
    color: #86EBF1;
}

.Cws-ob-selection-close-icon:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

/* Hide scrollbar by default */
.Cws-ob-selection-page::-webkit-scrollbar {
    width: 10px;
    background: transparent;
}

/* Show scrollbar on hover */
.Cws-ob-selection-page::-webkit-scrollbar {
    width: 10px;
}

/* Style the scrollbar thumb */
.Cws-ob-selection-page::-webkit-scrollbar-thumb {
    border: 5px solid #E0E0E0;
    background: transparent;
    border-radius: 10px;
}

.Cws-ob-selection-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 1%;
    flex-direction: row;
    color: #1a1d31;
}

.Cws-ob-selection-checkbox span {
    font-family: "Inter";
    font-size: 12px;
    margin-right: 10px;
    white-space: nowrap;
}

.Cws-ob-selection-checkbox input {
    display: inline;
}

/* Customize the checkbox appearance */
.Cws-ob-selection-checkbox input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border: 2px solid #86EBF1;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: background 0.3s, border-color 0.3s;
}

/* Style for the checkbox when checked */
.Cws-ob-selection-checkbox input[type="checkbox"]:checked {
    background-color: #86EBF1;
    border-color: #86EBF1;
    position: relative;
}

/* New Styles for Preview Feature */
.Cws-ob-selection-user-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    height: 100%;
    gap: 20px;
}

.Cws-ob-selection-upload {
    margin-bottom: 10px;
    background-color: #fff;
    border: 2px dashed #86EBF1;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    color: #86EBF1;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Cws-ob-selection-upload:hover {
    background-color: #f0f0f0;
}

.Cws-ob-selection-upload input {
    display: none;
}

.Cws-ob-selection-preview-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: 200px;
    border: 2px solid #E0E0E0;
    border-radius: 10px;
    background-color: #f9f9f9;
    overflow: hidden;
}

.Cws-ob-selection-preview-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.Cws-ob-selection-inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
}
.Cws-ob-selection-position-input{
    width: 100%;
    margin-bottom: 1rem;
}

.Cws-ob-selection-position-input input {
    
    padding: 5px;
    border: 1px solid #ccc;
    width: 80%;
    border-radius: 10px;
}

.Cws-ob-selection-position-input input:focus {
    border-color: #86EBF1;
    outline: none;
}


.Cws-ob-selection-input-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.Cws-ob-selection-input-box input {
    width: 50%;
}
