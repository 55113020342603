.rename-modal {
    width: 300px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
}

.rename-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.rename-modal-input-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

.rename-modal-input-group input[type="text"] {
    flex: 1;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.rename-modal-button-group {
    display: flex;
}

.rename-modal-add-button,
.rename-modal-cancel-button {
    padding: 5px 10px;
    font-size: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.rename-modal-add-button {
    background-color:  #86EBF1;
    color: white;
}

.rename-modal-add-button:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

.rename-modal-cancel-button {
    background-color: #E0E0E0;
    color: white;
}

.rename-modal-cancel-button:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

.rename-modal-input-group input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border: 2px solid  #86EBF1;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: background 0.3s, border-color 0.3s;
}

/* Style for the checkbox when checked */
.rename-modal-input-group input[type="checkbox"]:checked {
    background-color: #86EBF1;
    border-color:  #86EBF1;
    position: relative;
}
