.Cws-save-project-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 4px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.Cws-save-project-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
}

.Cws-save-project-form {
    margin: 20px 0;
}

.Cws-save-project-form label {
    display: block;
    margin-bottom: 5px;
}

.Cws-save-project-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.Cws-save-project-add-button {
    background-color:  #86EBF1;
    color: white;
}

.Cws-save-project-add-button:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

.Cws-save-project-cancel-button {
    background-color: #E0E0E0;
    color: white;
}

.Cws-save-project-cancel-button:hover {
    opacity: 0.8;
    transform: scale(1.1);
}