/* CwsRightSec.css */

.Cws-fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    outline: none; /* Remove outline for the modal */
}

.Cws-fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.Cws-fullscreen-open-icon{
    cursor: pointer;
    color:  #60d8e0;
    border: 2px solid  #60d8e0;
    display: flex;
    padding: 1%;
    border-radius: 5px;
    background-color: #def6f7;
    
}

.Cws-fullscreen-open-icon:hover {
    opacity: 0.8;
    transform: scale(1.1);
}


.Cws-fullscreen-close-icon {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    color: #60d8e0;
    z-index: 1100; /* Ensure the button is on top */
}

.Cws-fullscreen-close-icon:hover {
    color: red;
}

/* Existing styles */
.Cws-right-sec-con {
    display: flex;
    flex-direction: column;
    height: 100vh;
    
}

.Cws-right-sec-head {
    position: relative;
    margin-top: 3%;
    height: 5%;
    display: flex;
    background-color: #fbfbfb;
    flex-direction: row;
    
}

.Cws-right-sec-head-half-l {
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 4%;
   
}

.Cws-right-sec-head-half-r {
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    margin-right: 2%;
}

.Cws-right-sec-tab {
    width: 100%;
    height: 100%;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 10px 10px 0 0;
    background-color: #f7f7f7;
    color: #646464;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 700;
}

.Cws-right-sec-tab.Cws-right-sec-active {
    background-color: #fff;
    color: #5BD2D9 ;
    border-top: 2px solid #e0e0e0;
    border-left: 2px solid #e0e0e0;
    border-right: 2px solid #e0e0e0;
    border-bottom: 2px solid #e0e0e0;
}

.Cws-right-sec-body {
    height: 92%;
    display: flex;
    flex-direction: column;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
    gap: 2%;
}

.Cws-right-sec-recon {
    height: 60%;
}

.Cws-right-sec-obcon {
    height: 40%;
}


