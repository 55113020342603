.Typo-footer-con {
    display: flex;
    flex-direction: column;
    height: 20vh;
    width: 100%;
    background-color: #2c3e50; /* 어두운 파란색 배경 */
    padding: 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ecf0f1; /* 밝은 텍스트 색상 */
    font-family: 'Roboto', sans-serif; /* 모던한 폰트 */
    border-top: 5px solid #65eaff; /* 상단에 강조된 빨간색 선 */
}

.Typo-footer-text {
    line-height: 1.8;
    color: #bdc3c7; /* 은은한 회색 텍스트 */
    font-size: 15px;
    margin: 8px 0;
    max-width: 800px; /* 텍스트 최대 너비 설정 */
}

.Typo-footer-link {
    color: #bdc3c7; /* 은은한 회색 텍스트 */
    text-decoration: none;
    font-weight: bold;
}



/* 반응형 디자인 - 모바일에서의 스타일 조정 */
@media (max-width: 600px) {
    .Typo-footer-con {
        height: auto;
        padding: 15px;
    }

    .Typo-footer-text {
        font-size: 14px;
    }
}
