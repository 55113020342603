/* CelebrateModal.css */
.celebrate-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: #fff;
    text-align: center;
}

.celebrate-modal-content {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer; /* Make the whole modal clickable */
}

.celebrate-modal-message {
    font-size: 3.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffda79;
    z-index: 2;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);  /* Add a shadow for visibility */
}

.celebrate-modal-fireworks {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Allow clicks to pass through the canvas */
}
