.Typo-banner-con {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    width: 100%; /* Ensures it takes the full width */
    color: aliceblue;
    flex-direction: column;
    background-color: #1f1f33; /* Background color matching the GIF */
}

.centered-gif {
    width: 50vh; /* Ensures the width matches the height */
    height: 50vh; /* Keeps it circular by fixing height and width */
    border-radius: 50%; /* Makes the image circular */
    object-fit: cover; /* Ensures the GIF is contained within the circle without distortion */
}
