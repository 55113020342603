.Cws-command-ex-con {
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.Cws-command-ex-side {
    width: 10%;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #ccc;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 700;
    font-size: 12px;
}

.Cws-command-ex-side-icon-box:hover {
    background-color: #efefef;
}
.Cws-command-ex-side-icon-box.selected {
    background-color: #efefef; /* 선택된 상태의 배경색 */
}
.Cws-command-ex-side-icon-box {
    width: 95%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
}

.Cws-command-ex-main-con {
    height: 100%;
    width: 94%;
    padding-left: 2%;
    overflow: hidden;
}
.Cws-command-ex-main{
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

/* Hide scrollbar by default */
.Cws-command-ex-main::-webkit-scrollbar {
    width: 10px;
    background: transparent;
    
}

/* Show scrollbar on hover */
.Cws-command-ex-main:hover::-webkit-scrollbar {
    width: 10px;

}

/* Style the scrollbar thumb */
.Cws-command-ex-main::-webkit-scrollbar-thumb {
    border: 5px solid #E0E0E0;
    background: transparent;
    border-radius: 10px;
}

.Cws-command-ex-side-icon-box .icon {
    font-size: 24px;
    margin-bottom: 5px; /* 간격 조절 */
}

.Cws-command-ex-side-icon-box .icon.move {
    color: #6BA6FF;
}

.Cws-command-ex-side-icon-box .icon.feature {
    color: #A993FF;
}

.Cws-command-ex-side-icon-box .icon.return {
    color: #FFAFED;
}

.Cws-command-ex-side-icon-box .icon.recon {
    color: #FFD380;
}

.Cws-command-ex-side-icon-box .icon.flow {
    color: #99D868;
}

.Cws-command-ex-side-icon-box .icon.clone {
    color: #86EBF1;
}
