.Cws-bg-set-modal {
    display: flex;
    width: 60%;
    height: 80%;
    margin: 5% auto;
    background-color: #f0f0f0;
    border-radius: 10px;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 700;
}

.Cws-bg-set-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.Cws-bg-set-sidebar {
    width: 20%;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure bottom items are at the bottom */
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 10px;
}

.Cws-bg-set-sidebar::-webkit-scrollbar {
    display: none;
}

.Cws-bg-set-sidebar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.Cws-bg-set-category-top {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Cws-bg-set-category-box {
    margin-bottom: 10px;
    background: #DeF6f7;
    border: none;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    color: #86EBF1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.Cws-bg-set-inputs input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    width: 80%;
    border-radius: 10px;
}

.Cws-bg-set-inputs input:focus {
    border-color: #86EBF1; /* Focus state border color */
    outline: none; /* Remove default outline */
}

.Cws-bg-set-selection-con {
    width: 80%;
    padding: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Cws-bg-set-page {
    width: 90%;
    height: 90%;
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    padding: 20px;
    overflow-y: auto;
}

.Cws-bg-set-box {
    position: relative;
    width: calc(15% - 10px);
    height: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #E0E0E0;
    background-color: #fff;
    justify-content: center;
}

.Cws-bg-set-box:hover {
    opacity: 0.8;
    transform: scale(1.05);
    border-color: #86EBF1;
}

.Cws-bg-set-box.active {
    border-color: #86EBF1;
    background-color: #fff;
}

.Cws-bg-set-img-box {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Cws-bg-set-img-box img {
    max-width: 80%;
    max-height: 80%;
    height: auto;
    width: auto;
    object-fit: contain;
}

.Cws-bg-set-box-name {
    text-align: center;
    color: #1a1d31;
    width: 100%;
    height: 30%;
    font-family: "Inter";
    margin-top: 10%;
    font-size: 12px;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Cws-bg-set-custom-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #86EBF1;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 10px;
}

.Cws-bg-set-custom-button:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

.Cws-bg-set-close-icon {
    position: fixed;
    top: 12%;
    left: 78%;
    cursor: pointer;
    color: #86EBF1;
}

.Cws-bg-set-close-icon:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

/* Hide scrollbar by default */
.Cws-bg-set-page::-webkit-scrollbar {
    width: 10px;
    background: transparent;
}

/* Show scrollbar on hover */
.Cws-bg-set-page::-webkit-scrollbar {
    width: 10px;
}

/* Style the scrollbar thumb */
.Cws-bg-set-page::-webkit-scrollbar-thumb {
    border: 5px solid #E0E0E0;
    background: transparent;
    border-radius: 10px;
}

.Cws-bg-set-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 1%;
    flex-direction: row;
    color: #1a1d31;
}

.Cws-bg-set-checkbox span {
    font-family: "Inter";
    font-size: 12px;
    margin-right: 10px;
    white-space: nowrap; /* Prevents text from wrapping */
}

.Cws-bg-set-checkbox input {
    display: inline;
}

/* Customize the checkbox appearance */
.Cws-bg-set-checkbox input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border: 2px solid #86EBF1;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: background 0.3s, border-color 0.3s;
}

/* Style for the checkbox when checked */
.Cws-bg-set-checkbox input[type="checkbox"]:checked {
    background-color: #86EBF1;
    border-color: #86EBF1;
    position: relative;
}

/* New Styles for the Updated Components */
.Cws-bg-set-user-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    height: 100%;
    gap: 20px; /* Add space between child elements */
}

.Cws-bg-set-upload-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.Cws-bg-set-upload {
    margin-bottom: 10px;
    background-color: #fff;
    border: 2px dashed #86EBF1;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    color: #86EBF1;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Cws-bg-set-upload:hover {
    background-color: #f0f0f0;
}

.Cws-bg-set-upload input {
    display: none;
}

.Cws-bg-set-preview-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: 200px;
    border: 2px solid #E0E0E0;
    border-radius: 10px;
    background-color: #f9f9f9;
    overflow: hidden;
}

.Cws-bg-set-preview-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.Cws-bg-set-inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Add space between input and button */
    width: 100%;
}

.Cws-bg-set-input {
    width: 50%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.Cws-bg-set-input:focus {
    border-color: #86EBF1;
    outline: none;
}

.Cws-bg-set-button {
    padding: 10px 20px;
    background-color: #86EBF1;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s, transform 0.3s;
}

.Cws-bg-set-button:hover {
    opacity: 0.8;
    transform: scale(1.05);
}

.Cws-bg-set-inputs{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Cws-bg-set-input-box{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.Cws-bg-set-input-box input{
    width: 50%;
}

.Cws-bg-set-tile-page{
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 5%;
}

.Cws-bg-set-tile-page-top{
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.Cws-bg-set-tile-page-top .Cws-bg-set-box{
    height: 30%;
}

.Cws-bg-set-tile-page-bottom{
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.Cws-bg-set-tile-page-bottom-t{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 30%;
}

.Cws-bg-set-tile-page-bottom-t-box{
    margin-bottom: 5px;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1%;
}

.Cws-bg-set-tile-page-bottom-t-box input {
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 40px;
    text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.Cws-bg-set-tile-page-bottom-t-box input::-webkit-outer-spin-button,
.Cws-bg-set-tile-page-bottom-t-box input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.Cws-bg-set-tile-page-bottom-t-box input[type="number"] {
    -moz-appearance: textfield;
}

.Cws-bg-set-fixed-editor .Cws-toggle-editor-con {
    height: 200px; 
}

.Cws-bg-set-tile-page-bottom-t-button {
    margin-left: 10px;
    border-radius: 5px;
    background-color: #86EBF1;
    border: none;
    color: white;
    cursor: pointer;
}

.Cws-bg-set-tile-page-bottom-t-button:hover {
    background-color: #fff;
    color: #86EBF1;
}
