.Cws-project-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 4px;
    width: 400px;
    height: 600px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
}
/* Hide scrollbar by default */
.Cws-project-modal-list::-webkit-scrollbar {
    width: 10px;
    background: transparent;
    
}

/* Show scrollbar on hover */
.Cws-project-modal-list:hover::-webkit-scrollbar {
    width: 10px;

}

/* Style the scrollbar thumb */
.Cws-project-modal-list::-webkit-scrollbar-thumb {
    border: 5px solid #E0E0E0;
    background: transparent;
    border-radius: 10px;
}
.Cws-project-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
}

.Cws-project-modal-list {
    margin: 20px 0;
}

.Cws-project-modal-item {
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.Cws-project-modal-item:hover {
    background-color: #f0f0f0;
}

.Cws-project-close-icon{
    position: absolute;
    top: 10%;
    left: 90%;
    cursor: pointer;
    color:  #86EBF1;
}

.Cws-project-close-icon:hover{
    opacity: 0.8;
    transform: scale(1.1);
}