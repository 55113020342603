.TypoMainPage-con{
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #fff;
}
.TypoMainPage-head{
    background-color:#65eaff;/* 상단 head 색상 */
    height: 8%;
    padding: 0.5%;
    display: flex;
    justify-content:center;
    align-items: center;
}
.TypoMainPage-body{
    display: flex;
    flex-direction: column;
    background-color:#fff;
}
.TypoMainPage-body-box{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}