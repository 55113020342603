.Typo-best-user-banner-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 80vh;
    margin: 0 auto 100px auto; /* 하단에 간격 추가 */
    font-family: 'Inter', sans-serif;
    font-family: 'Pretendard-Regular', sans-serif;
    font-weight: 700;
}

.Typo-best-user-banner-head {
    width: 100%;
    text-align: center;
    margin: 20px 0;
    background-color: aliceblue;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
}

.Typo-best-user-banner-head h2 {
    color: #333;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.Typo-best-user-banner-head span {
    color: #777;
    font-size: 16px;
}

.Typo-best-user-banner-head-botton-box {
    margin-top: 20px;
    position: absolute;
    bottom: 10%;
    right: 5%;
}

.Typo-best-user-banner-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 75%;
}

.Typo-best-user-banner-body-box-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 20px;
    width: 100%;
    height: 100%;
    padding: 20px;
}

.Typo-best-user-banner-body-box {
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #65eaff;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
}

.Typo-best-user-banner-body-box:hover {
    transform: scale(1.05);
    
}

.Typo-best-user-banner-body-box-top {
    width: 100%;
    height: 60%;
    background-color: #fff;
    border-bottom: 2px solid #65eaff;
}

.Typo-best-user-banner-body-box-bottom {
    height: 40%;
    width: 100%;
    border-top: 2px solid #65eaff;
    background-color: #fff;
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Typo-custom-button {
    background-color: #65eaff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-family: 'Inter', sans-serif;
    font-family: 'Pretendard-Regular', sans-serif;
    font-weight: 700;
}

.Typo-custom-button:hover {
    background-color: #339edb;
    transform: scale(1.05);
}

.Typo-custom-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.Typo-best-user-banner-footer {
    height: 20vh;
}
