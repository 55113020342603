
.Typo-nav-con{
    display: flex;
    width: 80%;
    height: 100%;
    flex-direction: row;
    
}
.Typo-nav-box-l{
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
.Typo-nav-box-m{
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 700;
    
}
.Typo-nav-box-r{
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 700;
}
.Typo-nav-item-box{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    font-size: 20px;
    margin-right: 8%;
    margin-left: 2%;
    gap: 10px;
    color: #fff;
    text-decoration: none; /* 밑줄 제거 */
    transition: transform 0.3s ease; /* 부드러운 크기 변화를 위해 transition 추가 */
}
.Typo-nav-item-box:hover {
    transform: scale(1.05); /* hover 시 크기를 1.05배로 키움 */
}