/* Main container */
.CwsStageLecture-con {
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    transition: all 0.3s ease; /* Smooth transitions for size changes */
}

/* Sidebar Toggle Button */
.CwsStageLecture-tabButton {
    background-color: #777;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    z-index: 1001;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: all 0.3s ease;
    position: absolute;
    top: 10px;
    left: 0;
}

/* Move the button inside the sidebar when it's open */
.CwsStageLecture-tabButton.inSidebar {
    position: relative;
    left: 0;
    top: 0;
    margin: 10px 0;
    z-index: 1;
    width: 100%;
    text-align: left;
    border-radius: 0;
}

/* Sidebar */
.CwsStageLecture-sidebar {
    width: 0;
    overflow: hidden;
    background-color: #222;
    color: white;
    transition: width 0.3s ease;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
}

.CwsStageLecture-sidebar.open {
    width: 250px;
    padding: 10px;
}

.CwsStageLecture-sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.CwsStageLecture-sidebar li {
    padding: 10px;
    cursor: pointer;
}

.CwsStageLecture-sidebar li.active {
    font-weight: bold;
    color: #ffeb3b;
}

/* Main content */
.CwsStageLecture-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    transition: margin-left 0.3s ease;
}

/* Title Section */
.CwsStageLecture-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    display: flex;
    align-content: center;
    justify-content: center;
}

/* YouTube Video Section */
.CwsStageLecture-video {
    width: 100%;
    max-width: 800px;
    aspect-ratio: 16 / 9;
    margin-bottom: 20px;
}

/* Description Section */
.CwsStageLecture-description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 5rem;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
    .CwsStageLecture-sidebar.open {
        width: 200px; /* Reduce sidebar width on smaller screens */
    }

    .CwsStageLecture-content.contentShift {
        margin-left: 200px; /* Adjust margin accordingly */
    }

    .CwsStageLecture-video {
        max-width: 600px; /* Reduce video size for smaller screens */
    }
}

@media screen and (max-width: 768px) {
    .CwsStageLecture-sidebar.open {
        width: 180px; /* Narrower sidebar for smaller devices */
    }

    .CwsStageLecture-content.contentShift {
        margin-left: 180px; /* Adjust margin for smaller screens */
    }

    .CwsStageLecture-video {
        max-width: 500px; /* Further reduce video size */
    }

    .CwsStageLecture-title {
        font-size: 1.3rem; /* Adjust font size for titles */
    }

    .CwsStageLecture-description {
        font-size: 1rem;
        line-height: 1.5; /* Slightly reduce line height */
    }
}

@media screen and (max-width: 480px) {
    .CwsStageLecture-sidebar.open {
        width: 150px; /* Narrower sidebar for mobile devices */
    }

    .CwsStageLecture-content.contentShift {
        margin-left: 150px; /* Adjust margin for mobile */
    }

    .CwsStageLecture-video {
        max-width: 100%;
        aspect-ratio: 16 / 9;
        height: auto;
    }

    .CwsStageLecture-title {
        font-size: 1.2rem; /* Smaller font size for titles on mobile */
    }

    .CwsStageLecture-description {
        font-size: 0.9rem;
    }

    .CwsStageLecture-tabButton {
        top: 5px;
        left: 5px;
        padding: 3px; /* Make button smaller on mobile */
    }
}
