.Cws-ob-con {
    display: flex;
    flex-direction: column;
    gap: 1%; /* Space between detail and list sections */
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;

    border: 2px solid #E0E0E0;
}

.Cws-ob-detail {
    background-color: #c2f8fd;
    padding: 2%;
    border-radius: 10px;
    color: #1a1d31;
    display: flex;
    flex-direction: column;
    gap: 5px; /* Space between sections */
    height: 30%;
    overflow: hidden;
    font-family: 'Inter';
    font-family: 'Pretendard-Regular';
    font-weight: 700;
}

.Cws-ob-main {
    height: 70%;
    width: 100%;
    overflow: hidden;
}
.Cws-ob-main-scroll-wrap{
    display: flex;
    flex-direction: column;
     /* Adjust height as needed */
 
}

/* Hide scrollbar by default */
.Cws-ob-list::-webkit-scrollbar {
    width: 10px;
    background: transparent;
    
}

/* Show scrollbar on hover */
.Cws-ob-list:hover::-webkit-scrollbar {
    width: 10px;

}

/* Style the scrollbar thumb */
.Cws-ob-list::-webkit-scrollbar-thumb {
    border: 5px solid #E0E0E0;
    background: transparent;
    border-radius: 10px;
}

.Cws-ob-list {
    display: flex;
    flex-wrap: wrap;
    height: 140px;
    gap: 10px; /* Space between object boxes */
    overflow-y: auto;
    overflow-x: hidden;   
    position: relative; /* To position the add icon relative to this container */
    padding: 1%;
}

.Cws-ob-init-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.Cws-ob-init-info-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color:  #5BD2D9;
    margin-bottom: 2%;
}

.Cws-ob-box {
    position: relative;
    width: calc(20% - 10px); /* Make sure 5 boxes fit per row, considering the gap */
    height: 60%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #E0E0E0;
    border-radius: 10px;
    cursor: pointer;
    color: #1a1d31;
    transition: opacity 0.3s, transform 0.3s, background-color 0.3s;
}
.Cws-ob-img-box {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Cws-ob-img-box img {
    max-width: 80%;
    max-height: 80%;
    height: auto;
    width: auto;
    object-fit: scale-down;
}
.Cws-ob-box-name {
    width: 100%;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    position:absolute;
    top: 80%;
}


.Cws-ob-box.add-new {
    background-color: #111;
    color: #5BD2D9;
    border: 2px solid #5BD2D9;
    border-radius: 10px;
}

.Cws-ob-box:hover, .Cws-ob-box.add-new:hover {
    opacity: 0.8; /* Makes the box slightly transparent on hover */
    transform: scale(1.05); /* Slightly scales up the box on hover */
    border-color:  #5BD2D9;
}

.Cws-ob-box.active {
    border-color:  #5BD2D9;
    background-color:#fff;
}

.Cws-ob-box.active .Cws-ob-box-name {
    border: 2px solid  #5BD2D9;
    border-radius: 6px;
    color: #fff;
    background-color:  #5BD2D9;
}

.Cws-ob-box.inactive {
    opacity: 0.5;
}


.Cws-ob-delete-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    color: #ff0000;
}

.Cws-ob-delete-icon:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

.Cws-ob-rename-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    cursor: pointer;
    color: #888;
    transition: color 0.3s;
}

.Cws-ob-rename-icon:hover {
    color: #333;
}


.Cws-ob-add-icon {
    position: fixed;
    bottom: 20px;
    right: 30px;
    cursor: pointer;
    color:  #5BD2D9;
}

.Cws-ob-add-icon:hover {
    opacity: 0.8;
    transform: scale(1.1);
}


.Cws-ob-selected {
    border-color:  #5BD2D9;
    background-color: #c2f8fd;
}

.Cws-ob-custom-button {
    border: 2px solid #5BD2D9;
    background-color: #fff;
    color: #5BD2D9;
    padding: 10px 20px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.Cws-ob-custom-button:hover {
    background-color: #5BD2D9;
    border-color: #5BD2D9;
    color: #fff; /* White text color on hover */
}


.Cws-ob-box img {
    width: 40px;
    height: 40px;
    margin-top: 10px; /* Add some space between the top and the image */
    margin-bottom: 10px; /* Add some space between the top and the image */
}

.Cws-ob-detail-round-box {
    border-radius: 20px; /* Rounded corners for a pill-like shape */
    background-color: #fff;
    padding: 5px 20px; /* Increased horizontal padding */
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20%; /* Ensure it doesn't shrink too much */
    max-width: 20%; /* Ensure it doesn't shrink too much */
    text-align: center;
    font-family: "Noto Sans Mono";
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
}

.Cws-ob-detail-controls {
    display: flex;
    flex-direction: row;
    gap: 25px; /* Space between control items */
    align-items: center;
    width: 100%;
}

.Cws-ob-control-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px; /* Space between icon, label, and box */
    width: 30%;
    
}

.Cws-ob-detail-icon {
    font-size: 20px;
    color: #1a1d31; /* Icon color */
}

.Cws-ob-control-item span {
    font-size: 14px; /* Label font size */
}

/* Custom Switch */
.Cws-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.Cws-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.Cws-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.Cws-slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .Cws-slider {
    background-color:  #5BD2D9;
}

input:checked + .Cws-slider:before {
    transform: translateX(20px);
}


/* Style for master objects */
@keyframes gradient-rotate-clockwise {
    0% {
        background-position: 0% 0%;
    }
    25% {
        background-position: 100% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    75% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

/* Style for master objects */
.Cws-ob-box.master {
    position: relative;
    border: 2px solid transparent;
    background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, #a8e6ff, #5BD2D9, #007bbd, #5BD2D9, #a8e6ff);
    background-origin: border-box;
    background-clip: content-box, border-box;
    animation: gradient-rotate-clockwise 10s linear infinite;
    background-size: 200% 200%;
}


.Cws-ob-box.master.active {
    border: 2px solid  #5BD2D9; /* Keeps the current selection style */
}